exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-farms-js": () => import("./../../../src/pages/farms.js" /* webpackChunkName: "component---src-pages-farms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pools-js": () => import("./../../../src/pages/pools.js" /* webpackChunkName: "component---src-pages-pools-js" */),
  "component---src-pages-swap-js": () => import("./../../../src/pages/swap.js" /* webpackChunkName: "component---src-pages-swap-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

